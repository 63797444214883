//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl , jsBtnUrl , jzBtnUrl , qtBtnUrl , titleUrl , textUrl } from '/src/utils/config.js';
import {
	getRegister,
	getNum,
	uploadData,
	getInfo,
	getSchoolInfo
} from '../../api'
export default {
	name: "index",
	data() {
		return {
			code: '',
			bgimgUrl:'',
			titleUrl:'',
			jsBtnUrl:'',
			jzBtnUrl:'',
			qtBtnUrl:'',
			textUrl:''
		}
	},
	components: {

	},
	created() {
		this.bgimgUrl = bgimgUrl;
		this.titleUrl = titleUrl;
		this.jsBtnUrl = jsBtnUrl;
		this.jzBtnUrl = jzBtnUrl;
		this.qtBtnUrl = qtBtnUrl;
		this.textUrl = textUrl;
		this.getCode()
	},
	methods: {
		isteacher(i) {
			this.$router.push({
				name: 'jpstatusInfo',
				query: {
					id: '1'
				}
			})
		},
		isParent(i) {
			this.$router.push({
				name: 'jpstatusInfo',
				query: {
					id: '2'
				}
			})
		},
		isMasses(i) {
			this.$router.push({
				name: 'jpstatusInfo',
				query: {
					id: '3'
				}
			})
		},

		// 非静默授权，第一次有弹框
		getCode() {
			let appId = 'wxf6cf313b0e14e06e'
			// let local = window.location.href // 获取页面url
			let local = 'https://jiatingpufa.kc87.com/jpchooseStatus' //后端给我这个链接了
			this.code = this.$route.query.code // 截取code
			//console.log(`${encodeURIComponent(local)}`)
			if (!this.code) { // 如果没有code，则去请求
				window.location.href =
					`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
			} else {
				sessionStorage.setItem('code', this.code);
				// 获取用户信息
				this.get_Info();
				//this.islogin = true
			}
		},
		// 截取url中的code方法
		getUrlCode() {
			let url = location.search
			this.winUrl = url
			let theRequest = new Object()
			if (url.indexOf("?") != -1) {
				let str = url.substr(1)
				let strs = str.split("&")
				for (let i = 0; i < strs.length; i++) {
					theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
				}
			}
			return theRequest
		},

		get_Info() {
			console.log(this.code)
			let token = localStorage.getItem('token')
			if (token == null || token == "") {
				getInfo({
					code: this.code
				}).then(res => {
					this.code = ""
					localStorage.setItem('token', res.data.data.token)
					console.log(localStorage.getItem('token'))
					console.log(res.data.data);
				})
			}
		},
	}
}
